import autoContactDash from '@/compositions/autoContactDash';
import contactDashFormat from '@/compositions/contactDashFormat';
import priceFormat from '@/compositions/priceFormat';

const numberonly = (el) => {
	// <input v-numberonly>
	// 숫자만 입력받고, 문자는 입력받지 않음
	el.addEventListener('input', (e) => {
		e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
	});
};

//const price = {
//	// <span v-price>
//	// 3자리 단위로 숫자 사이에 ,찍음
//	updated(el) {
//		let res;
//		if (!isNaN(el.textContent)) {
//			res = Intl.NumberFormat().format(Number(el.textContent));
//			el.textContent = res;
//		}
//	},
//};
const price = (el) => {
	// <span v-price>
	// <input v-price>
	// 3자리 단위로 숫자 사이에 ,찍음
	const tagName = el.tagName;
	if (tagName === 'INPUT') {
		el.addEventListener('input', (e) => {
			e.target.value = priceFormat(e.target.value.replaceAll(',', ''));
		});
	} else {
		el.textContent = priceFormat(el.textContent);
	}
};

const contact = (el) => {
	// <input v-contact>
	// <span v-contact>
	// 전화번호를 3,4,3자리로 끊고 사이에 - 찍음
	const tagName = el.tagName;
	if (tagName === 'INPUT') {
		el.addEventListener('input', (e) => {
			autoContactDash(e, e.target.value);
		});
	} else {
		el.textContent = contactDashFormat(el.textContent);
	}
};

const date = (el, binding) => {
	// <span v-date:"'YMD'">
	// <span v-date:"'YMDHm'">
	// <span v-date="'YMDHmS'">
	// 날짜 형식으로 변환함
	// el이 'YYYY-MM-DD HH:mm:SS' 형태로 들어올 것이라고 전제함
	if (el.textContent === '') return; // fetch Delay 동안에 빈 내용 무시
	const splited = el.textContent.split(' ');
	let [YMD, HMS] = splited;

	let res;
	// prettier-ignore
	switch (binding.value) {
			case 'YMD':
				res = 
					YMD.replace('-', '년 ').replace('-', '월 ').concat('일');
				break;
			case 'YMDHm':
				HMS = HMS.slice(0,6);
				res = 
					YMD.replace('-', '년 ').replace('-', '월 ').concat('일') 
					+ ' ' 
					+ HMS.replace(':', '시 ').replace(':', '분 ');
					break;
			case 'YMDHmS':
				res =
					YMD.replace('-', '년 ').replace('-', '월 ').concat('일') 
					+ ' ' 
					+ HMS.replace(':', '시 ').replace(':', '분 ') 
					+ '초'
		}
	el.textContent = res;
};

export { numberonly, price, contact, date };
