import { requestLogin, requestLogout } from '@/api/auth';
import { getRecentEvent } from '@/api/event';
import { getUserInfo } from '@/api/user';
import router from '@/router';
import store from '..';

export default {
	namespaced: true,
	state: {
		userInfo: {
			name: '',
			contact: '',
			email: '',
		},
		event_pk: 0, // 사용자가 가장 최근에 작성한 부고의 pk(없을 경우 0)
		invite_yn: false,
		place: {},
		isLogin: false,
	},
	mutations: {
		setLogout(state) {
			state.isLogin = false;
		},
		setLogin(state) {
			state.isLogin = true;
		},
		setUserInfo(state, payload) {
			state.userInfo = payload;
		},
		setRecentEvent(state, payload) {
			state.event_pk = payload;
		},
		setInviteInfo(state, payload) {
			state.invite_yn = payload.invite_yn;
			state.place = payload.place;
		},
		resetData(state) {
			for (let obj in state) {
				state[obj] = '';
			}
		},
	},
	actions: {
		SET_USER_INFO({ commit }) {
			return new Promise((resolve, reject) => {
				getUserInfo()
					.then((data) => {
						commit('setUserInfo', data.user);
						commit('setInviteInfo', { invite_yn: data.invite_yn, place: data.place });
						resolve();
					})
					.catch((fail) => {
						router.push({ name: 'Home' });
						reject();
					});
			});
		},
		SET_RECENT_EVENT({ commit }) {
			return new Promise((resolve, reject) => {
				getRecentEvent()
					.then(({ event_pk }) => {
						commit('setRecentEvent', event_pk);
						resolve();
					})
					.catch((fail) => {
						reject();
					});
			});
		},
		LOGIN({ commit, dispatch }, { contact, password }) {
			return new Promise((resolve, reject) => {
				requestLogin({ contact, password })
					.then(async ({ user }) => {
						const { access_token, refresh_token } = user;
						store.commit('token/setToken', { access_token, refresh_token });
						commit('setLogin');
						await dispatch('SET_USER_INFO');
						await dispatch('SET_RECENT_EVENT');

						// 휴면 계정 체크
						if (store.state.user.userInfo.status === '휴면') {
							router.push({ name: 'Dormant' });
							resolve();
							return;
						}

						// 매니저로 초대 받았는지 확인
						if (store.state.user.invite_yn) {
							router.push({ name: 'ManagersAccept' });
						} else {
							router.push({ name: 'Home' });
						}
						resolve();
					})
					.catch(({ message }) => {
						reject();
						//로그인 실패시 메시지 출력
						store.commit('toast/alert', message);
					});
			});
		},
		async LOGOUT({ commit }) {
			// 결과에 상관없이 무조건 로그아웃 시킴
			const res = await requestLogout();
			commit('setLogout');
			store.commit('token/clearToken');
			router.push({ name: 'Signin' });
		},
	},
};
