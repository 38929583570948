import useAxios from '@/compositions/useAxios';

const { fetchData, postData } = useAxios();

export function requestCertMsg(payload) {
	// 정보 입력받아서 인증번호 전송을 요청함
	return new Promise((resolve, reject) => {
		postData(
			'/sendCerti',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message); // 실패 메세지를 보내줌. catch() 함수의 인자로 받을 수 있음
			},
		);
	});
}
export function requestCertAgain(payload) {
	// 인증번호 재전송을 요청함
	return new Promise((resolve, reject) => {
		postData(
			'/sendReCerti',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message); // 실패 메세지를 보내줌. catch() 함수의 인자로 받을 수 있음
			},
		);
	});
}

export function responseCertMsg(payload) {
	// 사용자가 입력한 인증번호가 맞는지 확인을 요청함
	return new Promise((resolve, reject) => {
		postData(
			'/confirmVeri',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function sendSignup(payload) {
	// 회원가입 정보를 전송함
	return new Promise((resolve, reject) => {
		postData(
			'/signup',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function requestLogin(payload) {
	// 로그인 요청
	return new Promise((resolve, reject) => {
		postData(
			'/signin',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail);
			},
		);
	});
}

export function requestLogout() {
	// 로그아웃 요청
	return new Promise((resolve, reject) => {
		postData(
			'/logout',
			{},
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function requestResign() {
	// 회원탈퇴
	return new Promise((resolve, reject) => {
		postData(
			'/resign',
			{},
			(res) => {
				resolve(res);
			},
			(fail) => {
				console.log(fail);
				reject(fail.message);
			},
		);
	});
}

export function changePwd(payload) {
	// (로그인 하기 이전에) 비밀번호 변경
	return new Promise((resolve, reject) => {
		postData(
			'/changePass',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function changeUserInfo(payload) {
	// 개인정보 변경
	return new Promise((resolve, reject) => {
		postData(
			'/saveUser',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}

export function releaseDormancy(payload) {
	// 휴면해제
	return new Promise((resolve, reject) => {
		postData(
			'/releaseDormancy',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				reject(fail.message);
			},
		);
	});
}