export const modalOpenScrollFix = () => {
	const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
	document.body.style.position = 'fixed';
	document.body.style.top = `-${scrollY}`;
	document.body.style.right = 0;
	document.body.style.left = 0;
};
export const modalCloseScrollRestore = () => {
	const scrollY = document.body.style.top;
	document.body.style.position = '';
	document.body.style.top = '';
	document.body.style.left = '';
	document.body.style.right = '';

	window.scrollTo(0, parseInt(scrollY || '0') * -1);
};
