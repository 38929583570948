<template>
	<ToastAlert />

	<Splash v-if="isSplash" />

	<router-view v-slot="{ Component }">
		<transition :name="transitionName">
			<KeepAlive :include="keepAliveList">
				<component :is="Component" :key="$route.fullPath" />
			</KeepAlive>
		</transition>
	</router-view>

	<BottomMenuBar v-show="botMenuList.includes(currentPage)" />
</template>

<script>
	// 컴포넌트
	import Splash from './views/Splash.vue';
	import BottomMenuBar from './components/BottomMenuBar.vue';
	import ToastAlert from './components/modal/ToastAlert.vue';

	// vue 내장 라이브러리
	import { ref, watch, onMounted, onUnmounted, computed } from 'vue';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';
	import { useDeviceInfo } from './compositions/useDeviceInfo';
	// npm 라이브러리
	// util 라이브러리
	import { modalCloseScrollRestore } from '@/compositions/useModalScrollControl';
	// 백엔드 API

	export default {
		components: {
			BottomMenuBar,
			ToastAlert,
			Splash,
		},
		setup() {
			const keepAliveList = ['Search', 'PhoneNumChange', 'PayList'];

			// 캐싱할 컴포넌트를 지정함으로써 리렌더링을 막을 수 있음
			// input에 데이터를 작성한 상태에서, 다른 페이지에 갔다가 뒤로가기로 다시 돌아왔을 때 입력한 데이터가 사라지는 일을 방지하기 위함
			// 이 배열의 원소는 반드시 component의 name속성을 명시해야 적용됨. (router에서 붙인 name으로는 적용되는 게 아니니 유의)
			// window.onpopstate = function (event) {
			// 	if (event) {
			// 		modalCloseScrollRestore();
			// 	}
			// };

			return {
				keepAliveList,
				...useSlide(),
				...useSplash(),
				//...useScrollInfo(),
			};
		},
	};

	const useSplash = () => {
		const isSplash = ref(true);
		let isPageLoaded = true;
		let isTimerFinished = false;

		// 최소 1500ms
		let toId = setTimeout(() => {
			isTimerFinished = true;
			if (!isPageLoaded) {
				// 로드가 먼저 끝난 경우
				isSplash.value = false;
			}
			// 아직 로드 중일 경우
			clearTimeout(toId);
		}, 1500);

		window.addEventListener('load', () => {
			if (!isTimerFinished) {
				// 아직 최소시간이 안 지난 경우, 빠른 환경
				isPageLoaded = false;
				return;
			}
			// 최소 시간이 지난 경우
			isSplash.value = false;
		});

		return {
			isSplash,
		};
	};

	const useSlide = () => {
		// 바텀 메뉴바 페이지간 이동 : 효과 없음, 그 외에는 slide-right, 뒤로 가기 눌렀으면 slide-left
		let transitionName = ref('');
		let lastHistoryPosition = ref(window.history.state.position);
		const botMenuList = ['Home', 'Search', 'ManageList', 'Pay', 'MoreView']; // botMenuList에 있는 url에서만 바텀메뉴바를 노출해야함

		const noTransitionPage = [
			...botMenuList,
			'ServiceWriteStep1',
			'ServiceWriteStep2',
			'ServiceWriteStep3',
			'ServiceWriteStep4',
			'ServiceWriteStep5',
		];
		const route = useRoute();
		const store = useStore();
		const currentPage = computed(() => {
			return route.name;
		});

		watch(
			() => route.name,
			(to, from) => {
				const currentHistoryPosition = window.history.state.position;
				transitionName.value = 'none';
				const { deviceInfo } = useDeviceInfo();
				if (deviceInfo.isIos) return; // IOS에서는 트랜지션 효과 없앰
				if (store.state.header.isBack) {
					// 뒤로 가기 버튼 눌렸을 시 왼쪽에서 덮임
					transitionName.value = 'slide-left';
					store.commit('header/inactivateBack');
				} else if (noTransitionPage.includes(to) && noTransitionPage.includes(from)) {
					// 바텀메뉴 -> 바텀메뉴 간 이동 시 효과없음
					transitionName.value = 'none';
				} else {
					if (from === undefined) return; // 새로고침시
					if (currentHistoryPosition < lastHistoryPosition.value) return; // 헤더에 뒤로가기 버튼을 누르지 않고 스와이프로 뒤로가기시
					transitionName.value = 'slide-right';
				}
				lastHistoryPosition.value = currentHistoryPosition;
			},
		);

		//onMounted(() => {
		// 사파리 버그로 인해서 popstate 이벤트 감지 불가
		//	window.addEventListener('popstate', () => {
		//		alert('pop');
		//	});
		//});

		return {
			transitionName,
			botMenuList,
			currentPage,
			//
			...useScrollInfo(),
		};
	};

	const useScrollInfo = () => {
		//	const store = useStore();
		//	const scrollDirection = ref(0); // 스크롤 내릴 때 헤더 감출지 정할 수 있는 역할(이 프로젝트에서는 사용 안함)
		//	const scrollTrace = ref(0);
		//	const SCROLL_BUFFER = 100; //스크롤 최상단에서 얼마만큼 내려야 스크롤 방향을 감지할지 설정

		//	const handleScrollHeader = () => {
		//		const nextScrollTop = window.scrollY;
		//		if (nextScrollTop <= SCROLL_BUFFER) {
		//			scrollDirection.value = 0;
		//		} else if (nextScrollTop > scrollTrace.value) {
		//			scrollDirection.value = -1; // down
		//		} else if (nextScrollTop < scrollTrace.value) {
		//			scrollDirection.value = 1; // up
		//		}
		//		scrollTrace.value = nextScrollTop;
		//	};
		//	watch(scrollDirection, (value) => store.commit('setScrollInfo', { scrollDirection: value }));

		onMounted(() => {
			//document.addEventListener('scroll', handleScrollHeader);
			window.addEventListener('scroll', () => {
				document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
				//html 인라인 스타일로 scrollY값을 넣어놓고 다님으로써 모달창을 열 때 top:-scrollY 값을 줘서 해당 위치에 스크롤을 고정시킬 수 있음.
			});
		});
		//onUnmounted(() => {
		//	document.removeEventListener('scroll', handleScrollHeader);
		//});

		return {
			//scrollDirection,
		};
	};
</script>

<style>
	@import url('./assets/css/reset.css');
	@import url('./assets/css/common.css');
	@import url('./assets/css/common/transition.css');
	@import url('./assets/css/layout.css');
	@import url('./assets/css/components/header.css');
</style>
