<template>
	<div class="toast" v-if="$store.state.toast.toastContent">
		<div class="toast_container">
			<div class="toast_content">{{ $store.state.toast.toastContent }}</div>
		</div>
	</div>
</template>

<script>
	export default {
		setup() {},
	};
</script>

<style scoped>
	.toast {
		position: fixed;
		width: 100%;
		max-width: var(--inner-width);
		left: 50%;
		bottom: 12vh;
		transform: translate(-50%, 0);
		z-index: 1000;
		opacity: 1;
		transition-duration: 0.7s;
	}
	.toast.fade_out {
		opacity: 0;
		bottom: 10vh;
		/* transition: opacity 1s ease-in-out; */
	}
	.toast .toast_container {
		display: flex;
		justify-content: center;
	}
	.toast .toast_container .toast_content {
		width: calc(100% - 5rem);
		padding: 1rem 0 0.7rem 0;
		background-color: #2d2d2dea;
		color: #fff;
		font-size: var(--font-size-xx-small);
		line-height: 1.5;
		border-radius: 10px;
		text-align: center;
	}
</style>
