import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from '@/plugins/axios';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import LottieAnimation from 'lottie-web-vue';

import { login, logout } from '@/directive/login';
import { numberonly, price, contact, date } from '@/directive/format';

const app = createApp(App);

app.directive('login', login);
app.directive('logout', logout);
app.directive('numberonly', numberonly);
app.directive('price', price);
app.directive('contact', contact);
app.directive('date', date);

app.config.globalProperties.axios = axios;
dayjs.locale('ko');
app.use(store).use(router).use(dayjs).use(LottieAnimation).mount('#app');
