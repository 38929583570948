import { modalOpenScrollFix, modalCloseScrollRestore } from '@/compositions/useModalScrollControl';

export default {
	namespaced: true,
	state: {
		confirmModal: false,
		confirmMsg: '',
		confirmAcceptFn: () => {},
		confirmCancelFn: () => {},
	},
	mutations: {
		openConfirm(state, { msg, onAccept, onCancel }) {
			state.confirmModal = true;
			modalOpenScrollFix();
			state.confirmMsg = msg;
			if (onAccept) {
				state.confirmAcceptFn = onAccept;
			}
			if (onCancel) {
				state.confirmCancelFn = onCancel;
			}
		},
		closeConfirm(state) {
			state.confirmModal = false;
			state.confirmMsg = '';
			modalCloseScrollRestore();
		},
		acceptConfirm(state) {
			state.confirmAcceptFn();
			this.commit('confirm/closeConfirm');
		},
		cancelConfirm(state) {
			state.confirmCancelFn();
			this.commit('confirm/closeConfirm');
		},
	},
	actions: {},
	getters: {},
};
