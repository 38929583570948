import useAxios from '@/compositions/useAxios';
import store from '@/store';
import axios from 'axios';

const { fetchData, postData } = useAxios();

export function getEventList(params) {
	// 이벤트 검색 (장례식) 불러옴
	return new Promise((resolve) => {
		fetchData('/getEvent', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getEventDetail(params) {
	// 이벤트 검색 (장례식) 불러옴
	return new Promise((resolve) => {
		fetchData('/getEventDetail', params, (res) => {
			resolve(res.data);
		});
	});
}

export function saveEvent(params) {
	// 부고 작성 정보를 백엔드에 전송함
	return new Promise((resolve) => {
		postData('/saveEvent', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getEventManage(params) {
	// 부고 관리 정보를 불러옴
	return new Promise((resolve) => {
		fetchData('/getEventManage', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getRecentEvent() {
	// 유저의 최근 이벤트 정보를 불러옴
	return new Promise((resolve) => {
		fetchData('/getRecentEvent', {}, (res) => {
			resolve(res.data);
		});
	});
}

export function getEventContent(params) {
	// 상세 이벤트 정보를 불러옴
	return new Promise((resolve) => {
		fetchData('/getEventContent', params, (res) => {
			resolve(res.data);
		});
	});
}

export function sendMsg(payload) {
	// 조문메시지를 작성함
	return new Promise((resolve) => {
		postData('/saveMessage', payload, (res) => {
			resolve(res.data);
		});
	});
}

export function deleteMsg(payload) {
	// 조문메시지를 삭제함
	return new Promise((resolve) => {
		postData('/deleteMessage', payload, (res) => {
			resolve(res.data);
		});
	});
}

export function getHost(params) {
	// 상주 정보를 불러옴
	return new Promise((resolve) => {
		fetchData('/getHost', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getPayment(params) {
	// 부조 내역을 불러옴
	return new Promise((resolve) => {
		fetchData('/getPayment', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getPayDetail(params) {
	// 부조 상세내역을 불러옴
	return new Promise((resolve) => {
		fetchData('/getPaymentDetail', params, (res) => {
			resolve(res.data);
		});
	});
}

export function cancelPayment(payload) {
	// 부조 결제를 취소함
	return new Promise((resolve, reject) => {
		postData(
			'/cancelPayment',
			payload,
			(res) => {
				resolve(res.data);
			},
			(fail) => reject(fail),
		);
	});
}

export function getEventManager(params) {
	// 현장 매니저 목록을 불러옴
	return new Promise((resolve) => {
		fetchData('/getEventManager', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getEventMessagae(params) {
	// 현장 매니저 목록을 불러옴
	return new Promise((resolve) => {
		fetchData('/getEventMessage', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getEventPayment(params) {
	// 조의금 내역
	return new Promise((resolve) => {
		fetchData('/getEventPayment', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getBankInfo(params) {
	// 무통장 입금 정보 불러옴
	return new Promise((resolve) => {
		fetchData('/getBank', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getPayLimit(params) {
	// 결제 한도액 조회 (설정 한도 - 결제 액) 불러옴
	return new Promise((resolve) => {
		fetchData('/getPayLimit', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getEventCalc(params) {
	// 정산 데이터를 불러옴
	return new Promise((resolve) => {
		fetchData('/getEventCalc', params, (res) => {
			resolve(res.data);
		});
	});
}

export function getEventAppreciate(params) {
	// 감사장 보내기에 쓸 데이터를 불러옴
	return new Promise((resolve) => {
		fetchData('/getEventAppreciate', params, (res) => {
			resolve(res.data);
		});
	});
}

export function requestPay(params) {
	// 조의금 카드 결제 요청
	return new Promise((resolve, reject) => {
		fetchData('/requestPay', params, (res) => {
			resolve(res.data);
		});
	});
}

export function inviteManager(payload) {
	// 현장 매니저를 초대함
	return new Promise((resolve) => {
		postData('/inviteEventManager', payload, (res) => {
			resolve(res.data);
		});
	});
}

export function acceptManager(payload) {
	// 현장 매니저를 초대를 수락함
	return new Promise((resolve) => {
		postData('/agreeEventManager', payload, (res) => {
			resolve(res.data);
		});
	});
}

export function getContractInfo(params) {
	// 서비스 계약 정보 조회
	return new Promise((resolve) => {
		fetchData('/getContract', params, (res) => {
			resolve(res.data);
		});
	});
}
