// input 에 전화번호 입력할 때 자동으로 하이픈(-)넣어주는 기능
const autoContactDash = (e) => {
	e.target.value = e.target.value
		.replace(/[^0-9]/g, '') // 숫자만 입력 받기
		.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3') // 3자리,4자리,4자리로 끊고 -로 구분하기
		.replace(/(\-{1,2})$/g, ''); //eslint-disable-line
	//아직 숫자 입력되기 전의 -는 가려주기
	//val = e.target.value;
};
export default autoContactDash;
