export default {
	namespaced: true,
	state: {
		pay_event_pk: '', // 조의금을 결제할 부고의 pk (필수)
		event_manager_name: '', // 카드 결제시 선택한 상주의 이름
		price: 0, // 카드 결제시 결제할 금액
	},
	mutations: {
		setPayEventPk(state, payload) {
			state.pay_event_pk = payload;
		},
		setManagerName(state, payload) {
			state.event_manager_name = payload;
		},
		setPrice(state, payload) {
			state.price = payload;
		},
	},
	actions: {},
};
