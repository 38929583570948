import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import useAxios from '../compositions/useAxios';

// 로그인 여부 확인
function checkLogin(to, from, next, alertContent = '로그인이 필요합니다.') {
	if (store.state.token.access_token) {
		return;
	} else {
		store.commit('toast/alert', alertContent);
		router.push({ name: 'Signin' });
	}
}

// 이미 로그인했을 시 다시 들어가는거 방지
function loginGuard(next, alertContent = '이미 로그인/회원가입 하셨습니다.') {
	if (!store.state.token.access_token) {
		next();
	} else {
		store.commit('toast/alert', alertContent);
		router.push({ name: 'Home' });
	}
}

// 유저 정보 갱신
const { fetchData } = useAxios();
async function getUserInfo() {
	await fetchData('/getUserInfo', (data) => {
		store.commit('user/setUserInfo', data.user);
	});
}

// step 페이지 url 접근 막기
// const stepGuard = (next, to, from) => {

// };

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
	},

	//스플래쉬
	{
		path: '/splash',
		name: 'Splash',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Splash.vue'),
	},

	//로그인, 회원가입
	{
		path: '/signin',
		name: 'Signin',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth/Signin.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		props: true,
		meta: {
			title: '로그인',
		},
	},
	{
		path: '/signup/step1',
		name: 'SignupStep1',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth/SignupStep1.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		meta: {
			title: '회원가입',
		},
	},
	{
		path: '/signup/step2',
		name: 'SignupStep2',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth/SignupStep2.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		meta: {
			title: '회원가입',
		},
	},
	{
		path: '/signup/step3',
		name: 'SignupStep3',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth/SignupStep3.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		meta: {
			title: '회원가입',
		},
	},
	{
		path: '/passwordfind',
		name: 'PasswordFind',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth/PasswordFind.vue'),
		beforeEnter: (to, from, next) => {
			loginGuard(next);
		},
		meta: {
			title: '비밀번호 찾기',
		},
	},
	{
		path: '/phonenumchange',
		name: 'PhoneNumChange',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/Auth/PhoneNumChange.vue'),
	},

	// 더보기
	{
		path: '/moreview',
		name: 'MoreView',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/MoreView.vue'),
	},

	// 부고장
	{
		path: '/obituary/:eventPk?',
		name: 'Obituary',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Obituary/Obituary.vue'),
		beforeEnter: (to, from, next) => {
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Search' });
			} else next();
		},
	},
	{
		path: '/obituary/donation',
		name: 'DonationPay',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Obituary/DonationPay.vue'),
	},
	{
		path: '/obituary/method',
		name: 'DonationMethod',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Obituary/DonationMethod.vue'),
	},
	{
		path: '/obituary/donation/bank',
		name: 'DonationBank',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Obituary/DonationBank.vue'),
	},
	{
		path: '/obituary/donation/done',
		name: 'DonationDone',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Obituary/DonationDone.vue'),
	},
	{
		path: '/obituary/donation/fail/:outRsltMsg?',
		name: 'DonationFail',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Obituary/DonationFail.vue'),
	},
	{
		path: '/obituary/messagewrite/:eventPk',
		name: 'Messagewrite',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Obituary/MessageWrite.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Search' });
			} else next();
		},
	},

	// 부고 작성
	{
		path: '/servicerequest/terms',
		name: 'Serviceterms',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServiceTerms.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/servicerequest/write/step1',
		name: 'ServiceWriteStep1',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServiceWriteStep1.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/servicerequest/write/step2',
		name: 'ServiceWriteStep2',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServiceWriteStep2.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/servicerequest/write/step3',
		name: 'ServiceWriteStep3',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServiceWriteStep3.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/servicerequest/write/step4',
		name: 'ServiceWriteStep4',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServiceWriteStep4.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/servicerequest/write/step5',
		name: 'ServiceWriteStep5',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServiceWriteStep5.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/servicerequest/place',
		name: 'ServicePlace',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServicePlace.vue'),
	},
	{
		path: '/servicerequest/confirm',
		name: 'ServiceConfirm',
		component: () => import(/* webpackChunkName: "Service" */ '../views/ServiceRequest/ServiceConfirm.vue'),
	},

	// 부고 검색
	{
		path: '/search',
		name: 'Search',
		component: () => import(/* webpackChunkName: "Obituary" */ '../views/Search/SearchList.vue'),
	},

	// 부고 관리
	{
		path: '/manage',
		name: 'ManageList',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/ManageList.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/manage/contract/:eventPk?',
		name: 'ContractInfo',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/ContractInfo.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},
	{
		path: '/manage/cardedit/:eventPk?',
		name: 'CardEdit',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/ServiceRequest/ServiceConfirm'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},
	{
		path: '/manage/cardsend/:eventPk?',
		name: 'CardSend',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/CardSend.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},
	{
		path: '/manage/managers/:eventPk?',
		name: 'ManagerList',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/ManagerList.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},
	{
		path: '/manage/payhistory/:eventPk?',
		name: 'PayHistory',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/PayHistory.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},
	{
		path: '/manage/pay/:eventPk?',
		name: 'PayManage',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/PayManage.vue'),
		beforeEnter: (to, from, next) => {
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},
	{
		path: '/manage/appreciate/:eventPk?',
		name: 'Appreciate',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/Appreciate.vue'),
		beforeEnter: (to, from, next) => {
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},
	{
		path: '/manage/message/:eventPk?',
		name: 'ManageMessage',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Manage/ManageMessage.vue'),
		beforeEnter: (to, from, next) => {
			if (!to.params.eventPk) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},

	// 부고 결제
	{
		path: '/pay',
		name: 'Pay',
		component: () => import(/* webpackChunkName: "Pay" */ '../views/Pay/PayList.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/pay/detail/:payPK?',
		name: 'PayDetail',
		component: () => import(/* webpackChunkName: "Pay" */ '../views/Pay/PayDetail.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (!to.params.payPK) {
				store.commit('toast/alert', '올바르지 않은 접근입니다.');
				next({ name: 'Home' });
			} else next();
		},
	},

	// 매니저초대
	{
		path: '/managers/invite',
		name: 'ManagersInvite',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Managers/ManagersInvite.vue'),
		beforeEnter: (to, from, next) => {
			// checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/managers/accept',
		name: 'ManagersAccept',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Managers/ManagersAccept.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			if (store.state.user.invite_yn) {
				// 초대 받은 사람만 접근 가능
				next();
			} else {
				next({ name: 'Signin' });
			}
		},
	},

	// 장례정보
	{
		path: '/funeralinfo/process',
		name: 'Process',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Funeral/FuneralProcess.vue'),
	},
	{
		path: '/funeralinfo/manners',
		name: 'Manners',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Funeral/FuneralManners.vue'),
	},
	{
		path: '/funeralinfo/funeralwords',
		name: 'FuneralWords',
		component: () => import(/* webpackChunkName: "Manage" */ '../views/Funeral/FuneralWords.vue'),
	},

	// 개인정보 변경
	{
		path: '/edit',
		name: 'UserEdit',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/UserInfo/UserEdit.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/resign',
		name: 'ResignPage',
		component: () => import(/* webpackChunkName: "Auth" */ '../views/UserInfo/ResignPage.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/resign/done',
		name: 'ResignDone',
		component: () => import(/* webpackChunkName: "Dormant" */ '../views/UserInfo/ResignDone.vue'),
	},
	{
		path: '/dormant',
		name: 'Dormant',
		component: () => import(/* webpackChunkName: "Dormant" */ '../views/UserInfo/Dormant.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},
	{
		path: '/dormant/done',
		name: 'DormantDone',
		component: () => import(/* webpackChunkName: "Dormant" */ '../views/UserInfo/DormantDone.vue'),
		beforeEnter: (to, from, next) => {
			checkLogin(to, from, next);
			next();
		},
	},

	// 이용약관
	{
		path: '/terms',
		name: 'Terms',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/Terms.vue'),
	},
	// 개인정보처리방침
	{
		path: '/private',
		name: 'Private',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/Private.vue'),
	},
	// 고유식별정보처리
	{
		path: '/identification',
		name: 'Identification',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/Identification.vue'),
	},
	// 개인정보 제3자 제공동의
	{
		path: '/provide',
		name: 'Provide',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/Provide.vue'),
	},
	// 개인정보 취급 동의
	{
		path: '/terms/PersonalInfo/:telCom?',
		name: 'TermsPersonalInfo',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/TermsPersonalInfo.vue'),
	},
	// 본인확인서비스 이용약관
	{
		path: '/terms/SelfConfirm',
		name: 'TermsSelfConfirm',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/TermsSelfConfirm.vue'),
	},
	// 고유식별정보처리 동의
	{
		path: '/terms/Identification',
		name: 'TermsIdentification',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/TermsIdentification.vue'),
	},
	// 통신사 이용약관
	{
		path: '/terms/PressAgency/:telCom?',
		name: 'TermsPressAgency',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/TermsPressAgency.vue'),
	},
	// 개인정보 제3자 제공 동의
	{
		path: '/terms/ThirdPerson/:telCom?',
		name: 'TermsThirdPerson',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Terms/TermsThirdPerson.vue'),
	},
	//{
	//	// 404 페이지
	//	path: '/404',
	//	name: '404',
	//	component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
	//},
	{
		// 존재하지 않는 페이지 404로 리다이렉트
		path: '/:pathMatch(.*)*',
		component: () => import(/* webpackChunkName: "Home" */ '../views/404.vue'),
		//redirect: '/404',
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	//scrollBehavior(to, from, savedPosition) {
	//	// 기존 위치 존재하면 그 위치 반환
	//	if (savedPosition) {
	//		return savedPosition;
	//	}
	//	// 쿼리만 다를 때는 스크롤 업 안함
	//	if (to.path == from.path && JSON.stringify(to.query) != JSON.stringify(from.query)) return;

	//	if (to.path == '/' || to.path == from.path) {
	//		window.scrollTo({ top: 0, behavior: 'smooth' });
	//		return;
	//	}
	//	window.scrollTo({ top: 0 });
	//},
	scrollBehavior(){
		return { top: 0 }
	},
});

// 페이지 변경시 로딩 시작
router.beforeEach(async (to, from) => {
	if (store.state.user.isLogin && !store.state.token.access_token) {
		store.state.user.isLogin = false;
	} else if (store.state.token.access_token && !store.state.user.isLogin) {
		store.state.user.isLogin = true;
		await getUserInfo();
	}
});
// 페이지 변경 완료시 로딩 끝냄
router.afterEach(() => {
	// store.commit('loading/finishLoading');
});

export default router;
