// 백엔드 API
import { getEventContent, saveEvent } from '@/api/event';

// vue 내장 라이브러리
import router from '@/router';
import store from '@/store';

export default {
	namespaced: true,
	state: {
		//Step 1
		name: '',
		age: '',
		gender: '',
		awayDate: '', //작고일
		profileImage: {},
		profileThumb: '',
		//Step 2
		host: '',
		//Step 3
		location: {
			franchisee_name: '',
			franchisee_address: '',
			franchisee_address_detail: '',
		},
		room: '',
		parkingInfo: '',
		//Step 4
		coffinDate: '', //발인일
		coffinHour: '',
		coffinMinutes: '',
		coffinLocation: {
			place_name: '',
			road_address_name: '',
		},
		//Step 5
		openDuration: '',
		openStart: '',
		openEnd: '',

		limit: '',

		bank: '',
		accountHolder: '',
		accountNumber: '',

		// 부고 수정할 때만 사용하는 데이터
		event_pk: '',
		del_file_pk: '', // 삭제한 이미지 pk
		update_string: '', // 수정(추가)된 상주 pk, 콤마로 구분된 문자열로 보냄. 새롭게 추가된 상주는 값을 0으로 설정함
		delete_string: '', // 삭제된 상주 pk, 콤마로 구분된 문자열로 보냄
	},
	mutations: {
		setData(state, { key, value }) {
			state[key] = value;
		},
		setObituaryData(state, payload) {
			const { event, host, image, accno } = payload;
			state.event_pk = event.event_pk;
			state.name = event.name;
			state.age = event.age;
			state.gender = event.gender;
			state.awayDate = event.away_date;

			if (image[0]) {
				// 원래 사진이 등록되어 있었던 경우
				state.profileImage = image[0];
				state.profileThumb = window.location.protocol + image[0]?.path + image[0]?.save_name;
			} else {
				state.profileImage = '';
				state.profileThumb = '';
			}

			// 상주정보
			state.host = host;

			// 빈소 정보
			state.location = {
				franchisee_name: event.place_name,
				franchisee_address: event.address,
				franchisee_address_detail: event.address_detail,
			};
			state.room = event.room;
			state.parkingInfo = event.parking_guide;

			// 발인 정보
			state.coffinLocation = {
				place_name: event.coffin_place,
				road_address_name: event.coffin_address,
				y: event.coffin_lat,
				x: event.coffin_lng,
			};

			const [cDate, cHourMin] = event.coffin_date.split(' ');
			const [cHour, cMin, _] = cHourMin.split(':');
			state.coffinDate = cDate;
			state.coffinHour = cHour;
			state.coffinMinutes = cMin;

			// 부고장 공개 기간
			state.openStart = event.away_date;
			state.openEnd = event.end_date;

			//온라인 조의금 한도
			state.limit = accno[0]?.payment_limit / 10000;

			// 무통장입금 설정
			state.accountHolder = accno[0]?.holder;
			state.accountNumber = accno[0]?.accno;
			state.bank = accno[0]?.bank;

			// 부고 수정 시 사용되는 변수
			state.del_file_pk = null;
			state.update_string = host.map((item) => item.event_manager_pk);
			state.delete_string = [];
		},
		// 저장된 데이터 리셋함. 백엔드에 등록 완료하거나, 사용자가 처음으로 부고 작성할 때 호출.
		resetData(state) {
			for (let obj in state) {
				state[obj] = '';
			}
		},
	},
	actions: {
		async SET_OBITUARY(context, event_pk) {
			const res = await getEventContent({ event_pk });
			context.commit('setObituaryData', res);
		},
		SAVE_OBITUARY(context) {
			let hostRs = '';
			const host = context.state.host;
			host.forEach((host) => (hostRs += host.division + '|' + host.name + '!!'));

			const formData = new FormData();
			formData.append('name', context.state.name);
			formData.append('age', context.state.age);
			formData.append('gender', context.state.gender);
			formData.append('away_date', context.state.awayDate);
			formData.append('file', context.state.profileImage);

			formData.append('event_manager', hostRs);

			formData.append('franchisee_pk', context.state.location.franchisee_pk);
			formData.append('place_name', context.state.location.franchisee_name);
			formData.append('address', context.state.location.franchisee_address);
			formData.append('address_detail', context.state.location.franchisee_address_detail);
			formData.append('room', context.state.room);
			formData.append('parking_guide', context.state.parkingInfo);

			formData.append(
				'coffin_date',
				context.state.coffinDate + ' ' + context.state.coffinHour + ':' + context.state.coffinMinutes,
			);
			formData.append('coffin_place', context.state.coffinLocation.place_name);
			formData.append('coffin_address', context.state.coffinLocation.road_address_name);
			formData.append('coffin_lat', context.state.coffinLocation.y);
			formData.append('coffin_lng', context.state.coffinLocation.x);

			formData.append('end_date', context.state.openEnd);
			formData.append('payment_limit', context.state.limit * 10000);

			formData.append('bank', context.state.bank);
			formData.append('holder', context.state.accountHolder);
			formData.append('accno', context.state.accountNumber);

			// 기존에 작성된 부고를 수정할 경우 추가적으로 필요한 데이터
			formData.append('event_pk', context.state.event_pk);
			formData.append('del_file_pk', context.state.del_file_pk); // 이미지를 수정할 경우, 기존 이미지 pk
			formData.append(
				'update_string',
				context.state.update_string ? context.state.update_string.join(',') : context.state.update_string,
			); // 수정된 상주 pk
			formData.append(
				'delete_string',
				context.state.delete_string ? context.state.delete_string.join(',') : context.state.delete_string,
			); // 삭제된 상주 pk

			saveEvent(formData)
				.then((res) => {
					console.log(res);
					context.commit('resetData');
					router.push('/manage');
				})
				.catch((fail) => {
					console.log(fail);
				});
		},
	},
};
