import router from '../../router/index';

import { modalOpenScrollFix, modalCloseScrollRestore } from '@/compositions/useModalScrollControl';

export default {
	namespaced: true,
	state: {
		locationModal: false,
		shareModal: false,
		navModal: false,
		msgModal: false,
		payModal: false,
		serviceAgreeModal: false,
		serviceImageModal: false,
	},
	mutations: {
		openModal(state, popupName) {
			state[popupName] = true;
			modalOpenScrollFix();
		},
		closeModal(state, popupName) {
			state[popupName] = false;
			modalCloseScrollRestore();
		},
	},
	actions: {},
	getters: {},
};
