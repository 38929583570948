// vue 내장 라이브러리
import router from '@/router';

export default {
	namespaced: true,
	state: {
		//Step 1
		telCom: '',
		phoneNum: '',
		name: '',
		birth: '',
		gender: '',
		seqNo: '', // 휴대폰 번호 인증 고유번호
		//Step 2
		//Step 3
		email: '',
		password: '',
		intervalId: 0, // 휴대폰 인증 페이지에서 3분 타이머의 intervalId가 겹치지 않기 위해서 관리
	},
	mutations: {
		// Step 1
		setTelCom(state, payload) {
			state.telCom = payload;
		},
		setPhoneNum(state, payload) {
			state.phoneNum = payload;
		},
		setName(state, payload) {
			state.name = payload;
		},
		setBirth(state, payload) {
			state.birth = payload;
		},
		setGender(state, payload) {
			state.gender = payload;
		},
		// Step 2
		// Step 3
		setEmail(state, payload) {
			state.email = payload;
		},
		setPassword(state, payload) {
			state.password = payload;
		},
		setProperty(state, payload) {
			const { property, value } = payload;
			state[property] = value;
		},
		// 저장된 데이터 리셋함. 백엔드에 등록 완료하거나, 사용자가 처음으로 부고 작성할 때 호출.
		resetData(state) {
			for (let obj in state) {
				state[obj] = '';
			}
		},
	},
	actions: {
		// async SAVE_OBITUARY(context) {
		// 	var hostRs = "";
		// 	const host = context.state.host;
		// 	host.forEach(host => hostRs += host.relation + "|" + host.hostName + "!!");
		// 	const formData = new FormData();
		// 	formData.append('name', context.state.name);
		// 	formData.append('age', context.state.age);
		// 	formData.append('gender', context.state.gender);
		// 	formData.append('awayDate', context.state.awayDate);
		// 	formData.append('file', context.state.profileImage);
		// 	saveEvent(formData).then((res) => {
		// 		console.log(res);
		// 		router.push('/manage');
		// 	})
		// },
	},
};
