import useAxios from '@/compositions/useAxios';

const { fetchData, postData } = useAxios();

export function getUserInfo(params) {
	// 개인정보 불러옴
	return new Promise((resolve, reject) => {
		fetchData(
			'/getUserInfo',
			params,
			(res) => {
				resolve(res);
			},
			(fail) => {
				console.log(fail);
				reject(fail.message);
			},
		);
	});
}

export function savePass(payload) {
	// 개인정보 수정
	return new Promise((resolve, reject) => {
		postData(
			'/resign',
			payload,
			(res) => {
				resolve(res);
			},
			(fail) => {
				console.log(fail);
				reject(fail.message);
			},
		);
	});
}
