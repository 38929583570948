import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

//import alertBundle from './modules/(unused)alertBundle';
//import loading from "./modules/loading";
import header from './modules/header';
import modal from './modules/modal';
import obituary from './modules/obituary';
import signup from './modules/signup';
import toast from './modules/toast';
import token from './modules/token';
import user from './modules/user';
import pay from './modules/pay';
import confirm from './modules/confirm';

export default createStore({
	state: {
		scrollDirection: 0,
	},
	mutations: {
		setScrollInfo(state, { scrollDirection }) {
			if (scrollDirection !== undefined) state.scrollDirection = scrollDirection;
		},
	},
	actions: {},
	modules: {
		//alertBundle,
		//loading,
		header,
		modal,
		obituary,
		signup,
		toast,
		token,
		user,
		pay,
		confirm,
	},
	plugins: [
		createPersistedState({
			paths: ['user', 'pay', 'obituary'],
		}),
		createPersistedState({
			key: 'token',
			paths: ['token'],
		}),
	],
});
