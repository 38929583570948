<template>
	<div class="btm_menu_bar">
		<router-link to="/" class="btm_menu" :class="{ on: $route.name === 'Home' }">
			<div class="icon_wrap">
				<img src="@/assets/images/common/btm_menu/btm_menu_01.svg" class="icon_off" alt="홈" />
				<img src="@/assets/images/common/btm_menu/btm_menu_on_01.svg" class="icon_on" alt="홈" />
			</div>
			<p>홈</p>
		</router-link>
		<router-link to="/search" class="btm_menu" :class="{ on: $route.name === 'Search' }">
			<div class="icon_wrap">
				<img src="@/assets/images/common/btm_menu/btm_menu_02.svg" class="icon_off" alt="부고검색" />
				<img src="@/assets/images/common/btm_menu/btm_menu_on_02.svg" class="icon_on" alt="부고검색" />
			</div>
			<p>부고검색</p>
		</router-link>
		<router-link to="/manage" class="btm_menu" :class="{ on: $route.name === 'ManageList' }">
			<div class="icon_wrap">
				<img src="@/assets/images/common/btm_menu/btm_menu_03.svg" class="icon_off" alt="부고관리" />
				<img src="@/assets/images/common/btm_menu/btm_menu_on_03.svg" class="icon_on" alt="부고관리" />
			</div>
			<p>부고관리</p>
		</router-link>
		<router-link to="/pay" class="btm_menu" :class="{ on: $route.name === 'Pay' }">
			<div class="icon_wrap">
				<img src="@/assets/images/common/btm_menu/btm_menu_04.svg" class="icon_off" alt="부조내역" />
				<img src="@/assets/images/common/btm_menu/btm_menu_on_04.svg" class="icon_on" alt="부조내역" />
			</div>
			<p>부조내역</p>
		</router-link>
		<router-link to="/moreview" class="btm_menu" :class="{ on: $route.name === 'MoreView' }">
			<div class="icon_wrap">
				<img src="@/assets/images/common/btm_menu/btm_menu_05.svg" class="icon_off" alt="더보기" />
				<img src="@/assets/images/common/btm_menu/btm_menu_on_05.svg" class="icon_on" alt="더보기" />
			</div>
			<p>더보기</p>
		</router-link>
	</div>
</template>

<script>
	// 컴포넌트

	// vue 내장 라이브러리
	import { useStore } from 'vuex';

	// npm 라이브러리
	// 커스텀 라이브러리
	export default {
		setup() {
			const store = useStore();
			const toggleGnb = (action = 'toggle') => {
				if (action === 'toggle') {
					store.commit('header/toggle');
				} else if (action === 'open') {
					store.commit('header/open');
				}
			};

			return {
				toggleGnb,
			};
		},
	};
</script>

<style scoped src="@/assets/css/components/btm_menu.css"></style>
