<template>
	<div class="c_contents_container splash">
		<div class="c_section">
			<div class="c_inner">
				<div class="motion_wrap">
					<LottieAnimation :animationData="logoJSON" :loop="false" :autoPlay="true" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// 컴포넌트
	import logoJSON from '@/assets/images/motion/logo_motion.json';
	export default {
		setup() {
			return {
				logoJSON,
			};
		},
	};
</script>

<style scoped>
	.c_contents_container {
		position: relative;
		z-index: 9999;
	}
	.c_inner {
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 25%;
		box-sizing: border-box;
	}
</style>
